import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { initializeMainApp } from '../../assets/js/app.js';

function Accounts() {
  const [accountIndex, setAccountIndex] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [deletionComplete, setDeletionComplete] = useState(false);
  const accountstables = useRef(null);
  const accountsPerPage = 10;

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });


  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include', // Include cookies in the request
      });
  
      if (response.ok) {
        // Redirect to the login page after successful logout
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      // Display an error message to the user
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };
  

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });
          if (response.ok) {
            const data = await response.json();
            const userData = data.data;
            const userInfoData = {
              username: userData.username,
              profile_pic: userData.profile_pic,
              discord_id: userData.discord_id,
              membership: userData.membership,
            };
            setUserInfo(userInfoData);
            updateUserInfo(userInfoData);
          } else {
            // Handle the case where the fetch request fails
            console.error('Error fetching user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetchUserInfo:', error);
        }
      };

      const updateUserInfo = (info) => {
        const profile_pic = document.getElementById('profile_pic');
        if (profile_pic) {
          profile_pic.src = info.profile_pic;
        }
      };

      // Call the function to fetch and process the user data
      fetchUserInfo();
      initializeMainApp();
      effectRan.current = true;
    }
  }, []);

  const AccountseffectRan = useRef(false);
  useEffect(() => {
    if (AccountseffectRan.current === false) {
      const fetchAccounts = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/load_combo/loadaccounts`, {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.accounts){
              const newAccounts = data.accounts;
            setAccounts((prevAccounts) => [...prevAccounts, ...newAccounts]);
            }
          })
          .catch((error) => {
            console.error('Error fetching accounts:', error);
          });
      };
      fetchAccounts();
      AccountseffectRan.current = true;
    }
  }, [accountIndex]);

  const loadAccounts = () => {
    let content = '';
    const endIndex = Math.min(accountIndex + accountsPerPage, accounts.length);
    for (let i = accountIndex; i < endIndex; i++) {
      const account = accounts[i];
      content += (
        <tr key={i}>
          <td>{account.rbxusername}</td>
          <td>{account.rbxpassword}</td>
          <td>{account.membership}</td>
          <td>{account.robux}</td>
          <td>{account.credits}</td>
          <td>{account.revenue}</td>
        </tr>
      );
    }
    if (accountstables.current) {
      accountstables.current.innerHTML += content;
    }
    setAccountIndex(endIndex);
  };

  const downloadAccounts = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/load_combo/downloadaccounts`, {
      method: 'GET',
      credentials: 'include', // Include cookies in the request
    })
      .then((response) => response.blob())
      .then((blob) => {
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'Accounts.txt';
        a.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const deleteAccounts = () => {
    Swal.fire({
      title: 'Delete Accounts',
      text: 'Are you sure you want to delete these Accounts?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_URL}/api/load_combo/deleteaccounts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
          credentials: 'include', // Include cookies in the request
        })
          .then((response) => {
            if (response.ok) {
              if (accountstables.current) {
                accountstables.current.innerHTML = '';
              }
              setAccounts([]);
              setAccountIndex(0);
              setDeletionComplete(true); // Set deletion completion to true
              Swal.fire('Success', 'Accounts Combo Deleted', 'success');
            } else {
              // Handle delete error
              console.error('Failed to delete accounts');
            }
          })
          .catch((error) => {
            console.error('Error during delete accounts:', error);
          });
      }
    });
  };

  // Load more accounts when scrolling to the bottom
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      loadAccounts();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Accounts</a>
                    </li>
                    <li className="breadcrumb-item active">Accounts Storage</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>
            {/* end page-title */}
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Accounts</h4>
                    <p className="sub-title">Account Storage.</p>

                    <div className="mt-3 mb-2 d-flex justify-content-left">
                      <button
                        className="btn btn-primary waves-effect waves-light mx-2"
                        onClick={downloadAccounts}
                      >
                        Download Accounts
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={deleteAccounts}
                      >
                        Delete Accounts
                      </button>
                    </div>

                    <div className="table-responsive">
                      
                      {deletionComplete ? (
                        <table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Username</th>
                            <th>Password</th>
                            <th>Membership</th>
                            <th>Robux</th>
                            <th>Credits</th>
                            <th>Revenue</th>
                          </tr>
                        </thead>
                      </table>

                      ) : (
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th>Password</th>
                              <th>Membership</th>
                              <th>Robux</th>
                              <th>Credits</th>
                              <th>Revenue</th>
                            </tr>
                          </thead>
                          <tbody ref={accountstables} id="accountables">
                            {accounts.map((account, index) => (
                              <tr key={index}>
                                <td>{account.rbxusername}</td>
                                <td>{account.rbxpassword}</td>
                                <td>{account.membership}</td>
                                <td>{account.robux}</td>
                                <td>{account.credits}</td>
                                <td>{account.revenue}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container-fluid */}
          </div>
          {/* content */}
          <footer className="footer">© 2021 - 2022 Virizon Mgui</footer>
        </div>
        {/* ============================================================== */}
        {/* End Right content here */}
        {/* ============================================================== */}
      </div>
    </div>
  );
}

export default Accounts;
