import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

function Forgot() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleForgotPass = async (email, captcha) => {
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/forgot-pass`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
        { 
          email,
          captcha: captcha, 
        }),
      });

      if (response.ok) {
        Swal.fire('Success', 'Password reset instructions sent to your email.', 'success');
        setEmail('');
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      Swal.fire('Error', error.message || 'Password Reset Error. Please Try Again', 'error');
    } finally {
      setIsLoading(false);
      setCaptchaValue(null)
    }
  };

  const showLoginCaptcha = async (e) => {
    e.preventDefault(); 
    setIsLoading(true);
    if (!captchaValue) {
      setShowCaptchaModal(true);
      return;
    }
  };

  return (
    <>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        <div className="card card-pages shadow-none">
          <div className="card-body">
            <h5 className="font-18 text-center">Forgot Password</h5>
            <form className="form-horizontal m-t-30" onSubmit={(e) => showLoginCaptcha(e)}>
              <div className="form-group">
                <div className="col-12">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    required=""
                    placeholder="Email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group text-center m-t-20">
                <div className="col-12">
                  <button
                    className="btn btn-primary btn-block btn-lg waves-effect waves-light"
                    type="submit"
                    name="login"
                    id="submitButton"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
              <div className="form-group row m-t-30 m-b-0">
                <div className="col-sm-5 text-left">
                  <Link to="/login" className="text-muted">
                    <i className="far fa-address-card"></i> Login Page
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* CAPTCHA Modal */}
      <Modal
        isOpen={showCaptchaModal}
        onRequestClose={() => {
          setShowCaptchaModal(false);
          setIsLoading(false);
        }}
        contentLabel="CAPTCHA Modal"
        className="captcha-modal"
        overlayClassName="captcha-modal-overlay"
      >
        <button
          className="btn btn-danger close-button mb-5"
          onClick={() => {
            setShowCaptchaModal(false);
            setIsLoading(false);
          }}
        >
          X
        </button>

        <ReCAPTCHA
          sitekey="6LdTrMcoAAAAANBfdTku3iIQVoTmLZ8tSEFbUf-6"
          onChange={(value) => {
            setCaptchaValue(value);
            setShowCaptchaModal(false);
            handleForgotPass(email, value);
          }}
        />

      </Modal>
    </>
  );
}

export default Forgot;
