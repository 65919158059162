const $ = require('jquery')

export function initializeMainApp() {
    "use strict";

    //console.log("First Called")

    var MainApp = function () {
        this.$body = $("body");
        this.$wrapper = $("#wrapper");
        this.$btnFullScreen = $("#btn-fullscreen");
        this.$leftMenuButton = $('.button-menu-mobile');
        this.$menuItem = $('.has_sub > a');
    };

    MainApp.prototype.initSlimscrollmenu = function () {
        $('.slimscroll-menu').slimscroll({
            height: 'auto',
            position: 'right',
            size: "7px",
            color: '#9ea5ab',
            wheelStep: 5,
            touchScrollStep: 50
        });
    };

    MainApp.prototype.initSlimscroll = function () {
        $('.slimscroll-menu').slimscroll({
            height: 'auto',
            position: 'right',
            size: "7px",
            color: '#9ea5ab',
            wheelStep: 5,
            touchScrollStep: 50
        });
    };

    MainApp.prototype.initMetisMenu = function () {
        //metis menu
        $("#side-menu").metisMenu();
    };

    MainApp.prototype.initLeftMenuCollapse = function () {
        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
    
            $('body').toggleClass('enlarged');
    
            if ($('body').hasClass('enlarged')) {
                console.log("Enlarge Added");
            } else {
                console.log("Enlarge Removed");
            }
        });
    };
    
    
    MainApp.prototype.initEnlarge = function () {
        if (!this.enlargeInitialized && $(window).width() < 1025) {
            $('body').addClass('enlarged');
            this.enlargeInitialized = true;
        } else {
            if ($('body').data('keep-enlarged') !== true) {
                $('body').removeClass('enlarged');
            }
        }
    };

    MainApp.prototype.initActiveMenu = function () {
        // === following js will activate the menu in the left sidebar based on the URL ====

        $("#sidebar-menu a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href === pageUrl) {

                $(this).addClass("mm-active");
                $(this).parent().addClass("mm-active"); // add active to li of the current link
                $(this).parent().parent().addClass("mm-show");
                $(this).parent().parent().prev().addClass("mm-active"); // add active class to an anchor
                $(this).parent().parent().parent().addClass("mm-active");
                $(this).parent().parent().parent().parent().addClass("mm-show"); // add active to li of the current link
                $(this).parent().parent().parent().parent().parent().addClass("mm-active");
            }
        });
    };

    MainApp.prototype.initComponents = function () {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    };

    // Full screen
    MainApp.prototype.initFullScreen = function () {
        var $this = this;
        $this.$btnFullScreen.on("click", function (e) {
            e.preventDefault();

            if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        });
    };

    MainApp.prototype.init = function () {
        this.initMetisMenu();
        //this.slimscroll();
        this.initLeftMenuCollapse();
        this.initEnlarge();
        this.initActiveMenu();
        this.initComponents();
        this.initFullScreen();
    };

    // Initialize the MainApp
    var mainApp = new MainApp();
    mainApp.init();
}
