import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { initializeMainApp } from '../../assets/js/app.js';


function Dashboard() {

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [send_username, setSendUsername] = useState('');
  const [points, setPoints] = useState('');

  const [totalGamesInfo, setTotalGamesInfo] = useState({
    totalGames: 0,
    maxLimit: 0,
  });

  const handleSendPoints = (e) => {
    e.preventDefault();
    setIsSending(true);

    const payload = {
      username: send_username,
      points: points,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/send-points`, {
      method: 'POST',
      credentials: 'include', // Include cookies in the request
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (response.status === 200) {
          return response.json().then(data => {
            console.log('Response from server:', data.message);
            Swal.fire('Successfully', data.message, 'success');

            setSendUsername('');
            setPoints('');

            const pointstext = document.getElementById("total-points");
            pointstext.innerHTML = Intl.NumberFormat().format(data["points"]);
          });
        } else {
          return response.json().then(data => {
            console.error('Error:', data.message);
            Swal.fire('Error', data.message, 'error');
            throw new Error(data.message);
          });
        }
      })
      .catch(error => {
        console.error('Fetch Error:', error);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const [isBuying1, setIsBuying1] = useState(false);
  const [isBuying2, setIsBuying2] = useState(false);
  const [isBuying3, setIsBuying3] = useState(false);

  const buySlot = async (slotNumber, setIsBuying) => {
    console.log(slotNumber);
    setIsBuying(true); 

    const payload = {
      slotNumber: slotNumber,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/buy-games`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();

      if (response.status === 200) {

        const pointstext = document.getElementById("total-points");
        pointstext.innerHTML = Intl.NumberFormat().format(data["points"]);

        const currentpointstext = document.getElementById("current-points");
        currentpointstext.innerHTML = Intl.NumberFormat().format(data["points"]);

        const totalGames = totalGamesInfo.totalGames
        const totalGamesInfoData = {
          totalGames: totalGames,
          maxLimit: data.game_limit,
        };

        setTotalGamesInfo(totalGamesInfoData)

        Swal.fire('', data.message, 'success');
      } else {
        Swal.fire('Error', data.message, 'error');
      }
    } catch (error) {
      console.error(error);
      Swal.fire('Error', 'An error occurred.', 'error');
    } finally {
      setIsBuying(false);
    }
  };

  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };

  const [stats, setStats] = useState({
    points: 0,
    robux: 0,
    credits: 0,
  });

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });

  const [leaderboard, setLeaderboard] = useState([]);
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });

          if (!response.ok) {
            throw new Error(`Error fetching user data: ${response.statusText}`);
          }

          const data = await response.json();
          const userData = data.data;

          const userInfoData = {
            username: userData.username,
            profile_pic: userData.profile_pic,
            discord_id: userData.discord_id,
            membership: userData.membership,
          };

          setUserInfo(userInfoData);
          updateUserInfo(userInfoData);
          if (userData.stats) {
            const userStats = {
              points: Intl.NumberFormat().format(userData.stats.points) || 0,
              robux: Intl.NumberFormat().format(userData.stats.robux) || 0,
              credits: userData.stats.credits || 0,
            };
            setStats(userStats);
          } else {
            console.error('User stats not found');
          }
          
          
        } catch (error) {
          console.error('Error during fetchUserData:', error);
        }
      };

      const fetchTotalGamesInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/totalgames`, {
            method: 'GET',
            credentials: 'include',
          });

          if (!response.ok) {
            throw new Error(`Error fetching total games info: ${response.statusText}`);
          }

          const data = await response.json();
          const totalGamesInfoData = {
            totalGames: data.totalGames,
            maxLimit: data.maxLimit,
          };
          setTotalGamesInfo(totalGamesInfoData);
        } catch (error) {
          console.error('Error during fetchTotalGamesInfo:', error);
        }
      };

      const fetchLeaderboard = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/leaderboard`, {
            method: 'GET',
            credentials: 'include',
          });

          if (!response.ok) {
            throw new Error(`Error fetching leaderboard: ${response.statusText}`);
          }

          const data = await response.json();
          setLeaderboard(data.leaderboard);
        } catch (error) {
          console.error('Error during fetchLeaderboard:', error);
        }
      };

      const updateUserInfo = (info) => {
        const welcometag = document.getElementById('welcometext');
        const profile_pic = document.getElementById('profile_pic');

        fetch(`https://discordlookup.mesalytic.moe/v1/user/${info.discord_id}`)
          .then((response) => response.json())
          .then((data) => {
            const discordName = data.global_name;

            welcometag.textContent = `Hi ${info.username}! Welcome! aka ${discordName}`;
            profile_pic.src = info.profile_pic;
          })
          .catch((error) => {
            console.error('Error fetching JSON:', error);
          });
      };

      Promise.all([fetchUserData(), fetchTotalGamesInfo(), fetchLeaderboard()])
        .then(() => {
          setIsLoading(false);
          initializeMainApp();
        })
        .catch((error) => {
          console.error('Error during data fetching:', error);
        });
    }




    return () => {
      effectRan.current = true;
    };
  }, []);


  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}


            <ul className="metismenu" id="side-menu">

              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title" id="welcometext"></h4>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Virizon Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>


            {isLoading ? (
              <div className="col-lg-12 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>

                <div className="modal fade" id="sendpointsModal" tabIndex="-1" role="dialog" aria-labelledby="sendpointsModalLabel" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h6 className="modal-title" id="sendpointsModalLabel">Send Points</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSendPoints}>
                          <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder="Enter the site username"
                              value={send_username}
                              onChange={e => setSendUsername(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="points">Points</label>
                            <input
                              type="number"
                              className="form-control"
                              id="points"
                              placeholder="Enter the amount of points"
                              value={points}
                              onChange={(e) => setPoints(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                              name="addgame"
                              id="submitButton"
                              disabled={isSending} // Disable button when loading
                            >
                              {isSending ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                'Send Points'
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal fade" id="buygamesModal" tabIndex="-1" role="dialog" aria-labelledby="buygamesModalLabel" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h6 className="modal-title" id="buygamesModalLabel">Buy Games</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>Your current points: <span id="current-points" className="text-primary">{stats.points}</span></p>

                        <p>Game Slots:</p>
                        <ul className="list-group">
                          <li className="list-group-item bg-dark d-flex justify-content-between align-items-center">
                            <i className="mdi mdi-gamepad-variant mr-2"></i>
                            <strong className="text-white">+ 20 Games</strong>
                            <span className="badge badge-primary badge-pill">10,000 points</span>
                            <button
                              id="buyButton1"
                              className="btn btn-primary"
                              style={{ padding: '8px 16px', fontSize: '14px' }}
                              onClick={() => buySlot(1, setIsBuying1)}
                              disabled={isBuying2 || isBuying3}
                            >
                              {isBuying1 ? (
                                <span id="buyButtonSpinner1" className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                "Buy"
                              )}
                            </button>
                          </li>
                          <li className="list-group-item bg-dark d-flex justify-content-between align-items-center">
                            <i className="mdi mdi-gamepad-variant mr-2"></i>
                            <strong className="text-white">+ 40 Games</strong>
                            <span className="badge badge-primary badge-pill">20,000 points</span>
                            <button
                              id="buyButton2"
                              className="btn btn-primary"
                              style={{ padding: '8px 16px', fontSize: '14px' }}
                              onClick={() => buySlot(2, setIsBuying2)}
                              disabled={isBuying1 || isBuying3}
                            >
                              {isBuying2 ? (
                                <span id="buyButtonSpinner2" className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                "Buy"
                              )}
                            </button>
                          </li>
                          <li className="list-group-item bg-dark d-flex justify-content-between align-items-center">
                            <i className="mdi mdi-gamepad-variant mr-2"></i>
                            <strong className="text-white">+ 60 Games</strong>
                            <span className="badge badge-primary badge-pill">40,000 points</span>
                            <button
                              id="buyButton3"
                              className="btn btn-primary"
                              style={{ padding: '8px 16px', fontSize: '14px' }}
                              onClick={() => buySlot(3, setIsBuying3)}
                              disabled={isBuying1 || isBuying2}
                            >
                              {isBuying3 ? (
                                <span id="buyButtonSpinner3" className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              ) : (
                                "Buy"
                              )}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="button-items">
                  <button id="sendpointsButton" class="btn btn-primary mb-3" type="button" data-toggle="modal" data-target="#sendpointsModal">Send Points</button>
                  <button id="buygamesButton" class="btn btn-success mb-3" type="button" data-toggle="modal" data-target="#buygamesModal">Buy Games</button>
                </div>

                <div className="row">

                  <div className="col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-heading p-4">
                        <div className="mini-stat-icon float-right">
                          <i className="mdi mdi-cube-outline bg-primary text-white"></i>
                        </div>
                        <div>
                          <h5 className="font-16">Total Points</h5>
                        </div>
                        <h2 className="mb-0" id="total-points">{stats.points}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-heading p-4">
                        <div className="mini-stat-icon float-right">
                          <i className="mdi mdi-briefcase-check bg-success text-white"></i>
                        </div>
                        <div>
                          <h5 className="font-16">Total Robux</h5>
                        </div>
                        <h2 className="mb-0" id="robux">R$ {stats.robux}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-heading p-4">
                        <div className="mini-stat-icon float-right">
                          <i className="mdi mdi-tag-text-outline bg-warning text-white"></i>
                        </div>
                        <div>
                          <h5 className="font-16">Total Credits</h5>
                        </div>
                        <h2 className="mb-0" id="credits">$ {stats.credits}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-3">
                    <div className="card">
                      <div className="card-heading p-4">
                        <div className="mini-stat-icon float-right">
                          <i className="mdi mdi-buffer bg-danger text-white"></i>
                        </div>
                        <div>
                          <h5 className="font-16">Total Games</h5>
                        </div>
                        <h2 className="mb-0" id="games">{totalGamesInfo.totalGames} / {totalGamesInfo.maxLimit}</h2>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Leaderboard */}
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <h4 className="mt-0 header-title mb-4">Leaderboard</h4>
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Rank</th>
                                <th>Username</th>
                                <th>Robux</th>
                                <th>Points</th>
                                <th>Revenue</th>
                              </tr>
                            </thead>
                            <tbody>
                              {leaderboard.map((entry, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div>
                                      <img src={entry.profile_pic} alt="Profile Picture" className="thumb-sm rounded-circle mr-2" />
                                      {entry.username}
                                    </div>
                                  </td>
                                  <td>{`R$ ${entry.robux.toLocaleString()}`}</td>
                                  <td>{entry.points.toLocaleString()}</td>
                                  <td>{`R$ ${entry.revenue.toLocaleString()}`}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* end page-title */}

            {/* End Leaderboard */}
          </div>
          {/* container-fluid */}
        </div>
        {/* content */}
        <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
      </div>
      {/* ============================================================== */}
      {/* End Right content here */}
      {/* ============================================================== */}
    </div>

  );
}

export default Dashboard;
