// useAuth.js
import { useEffect, useState, useRef} from 'react';

async function SessionMiddleware() {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
      method: 'GET', // Adjust the HTTP method as needed
      credentials: 'include', // Include cookies in the request
    });

    console.log(response.status)

    if (response.ok) {
      const jsonData = await response.json();
      const userData = jsonData.data;

      if (userData.membership === 'Moderator' || userData.membership === 'Admin' || userData.membership === 'Owner') {
        return {"Admin": true, "Authenticated": true, "DiscordVerifed": true}
      }else{
        return {"Admin": false, "Authenticated": true , "DiscordVerifed": true}
      }
    } else if (response.status === 403 || response.status === 406) {
      if (response.status === 406){
        return {"Admin": false, "Authenticated": true, "DiscordVerifed": false}
      }
      return true; // Handle other cases as needed
    } else if (response.status === 401) {
      return false; // User is not authenticated
    }
  } catch (error) {
    console.error('Error during session check:', error);
  }
}


export default function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initialize as null for loading state
  const effectRan = useRef(false)

  useEffect(() => {

    if (effectRan.current === false){
      async function checkSession() {
        const isAuthenticated = await SessionMiddleware();
        console.log(isAuthenticated)
        setIsAuthenticated(isAuthenticated);
      }
      checkSession();
    }

    return () => {
      effectRan.current = true
    };
    
  }, []);

  return { isAuthenticated };
}
