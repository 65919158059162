import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Auth
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Forgot from './components/Auth/Forgot';
import ResetPassword from './components/Auth/ResetPassword';
import Purchase from './components/Auth/Purchase';

// Panel
import Dashboard from './components/Panel/Dashboard';
import Publish from './components/Panel/Publish';
import Admin from './components/Panel/Admin';
import AddGame from './components/Panel/AddGame';
import Games from './components/Panel/Games';
import Download from './components/Panel/Download';
import Configure from './components/Panel/Configure';
import Accounts from './components/Panel/Accounts';
import Cookies from './components/Panel/Cookies';
import Settings from './components/Panel/Settings';
import LoginChecker from './components/Panel/LoginChecker';
import Cookie from './components/Others/Cookie';

import DiscordVerification from './components/Panel/DiscordVerification';
import Blacklist from './components/Panel/Blacklist';

import NotFound from './components/NotFound';

// Middleware
import useAuth from './useAuth';
import useBlacklistInfo from './useBlacklistInfo';

// Assets
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/icons.css';
import './assets/css/metismenu.min.css';

//JS
import './assets/js/bootstrap.bundle.min.js';
import './assets/js/metismenu.min.js';

export default function App() {
  const { isAuthenticated } = useAuth();
  const { isBlacklisted } = useBlacklistInfo();
  const [isLoading, setIsLoading] = useState(true);

  console.log(isAuthenticated)
  

  useEffect(() => {
    if (isAuthenticated !== null && isBlacklisted !== null) {
      setIsLoading(false);
    }
  }, [isAuthenticated, isBlacklisted]);

  if (isLoading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  console.log(isAuthenticated.Admin)

  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/login"
            element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/register"
            element={!isAuthenticated ? <Register /> : <Navigate to="/" />}
          />
          <Route
            path="/forgot"
            element={!isAuthenticated ? <Forgot /> : <Navigate to="/" />}
          />
          <Route
            path="/purchase"
            element={!isAuthenticated ? <Purchase /> : <Navigate to="/" />}
          />
          <Route
            path="/reset-password"
            element={!isAuthenticated ? <ResetPassword /> : <Navigate to="/" />}
          />
          {isAuthenticated && isBlacklisted ? (
            <>
              <Route path="/" element={<Navigate to="/blacklist" />} />
              <Route path="/publish" element={<Navigate to="/publish" />} />
              <Route path="/addgame" element={<Navigate to="/blacklist" />} />
              <Route path="/games" element={<Navigate to="/blacklist" />} />
              <Route path="/download" element={<Navigate to="/blacklist" />} />
              <Route path="/configure" element={<Navigate to="/blacklist" />} />
              <Route path="/accounts" element={<Navigate to="/blacklist" />} />
              <Route path="/cookies" element={<Navigate to="/blacklist" />} />
              <Route path="/settings" element={<Navigate to="/blacklist" />} />
              <Route path="/lc" element={<Navigate to="/blacklist" />} />
              <Route path="/view-cookie" element={<Navigate to="/blacklist" />} />
           </>
          ) : (
            <>
              {isAuthenticated && !isAuthenticated?.DiscordVerifed ? (
                <>
                  <Route path="/" element={<Navigate to="/discord-verification" />} />
                  <Route path="/publish" element={<Navigate to="/discord-verification" />} />
                  <Route path="/addgame" element={<Navigate to="/discord-verification" />} />
                  <Route path="/games" element={<Navigate to="/discord-verification" />} />
                  <Route path="/download" element={<Navigate to="/discord-verification" />} />
                  <Route path="/configure" element={<Navigate to="/discord-verification" />} />
                  <Route path="/accounts" element={<Navigate to="/discord-verification" />} />
                  <Route path="/cookies" element={<Navigate to="/discord-verification" />} />
                  <Route path="/settings" element={<Navigate to="/discord-verification" />} />
                  <Route path="/lc" element={<Navigate to="/discord-verification" />} />
                  <Route path="/view-cookie" element={<Navigate to="/discord-verification" />} />
                </>
              ) : (
                <>
                  <Route
                    path="/"
                    element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/publish"
                    element={isAuthenticated ? <Publish /> : <Navigate to="/publish" />}
                  />
                  <Route
                    path="/addgame"
                    element={isAuthenticated ? <AddGame /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/games"
                    element={isAuthenticated ? <Games /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/download"
                    element={isAuthenticated ? <Download /> : <Navigate to="/login" />}
                  />
                   <Route
                    path="/configure"
                    element={isAuthenticated ? <Configure /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/accounts"
                    element={isAuthenticated ? <Accounts /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/cookies"
                    element={isAuthenticated ? <Cookies /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/settings"
                    element={isAuthenticated ? <Settings /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/lc"
                    element={isAuthenticated ? <LoginChecker /> : <Navigate to="/login" />}
                  />
                  <Route
                    path="/view-cookie"
                    element={isAuthenticated ? <Cookie /> : <Navigate to="/login" />}
                  />
  
                  {isAuthenticated ? (
                    isAuthenticated?.Admin ? (
                      <Route path="/admin" element={<Admin />} />
                    ) : (
                      <Route path="/admin" element={<Navigate to="/404" />} />
                    )
                  ) : (
                    <>
                      <Route path="/login" element={<Login />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                    </>
                  )}
  
                  {isAuthenticated && !isBlacklisted && (
                    <Route path="/blacklist" element={<Navigate to="/" />} />
                  )}
                  {isAuthenticated && isAuthenticated?.DiscordVerifed && (
                    <Route path="/discord-verification" element={<Navigate to="/" />} />
                  )}

                  {!isAuthenticated && (
                    <Route path="/discord-verification" element={<Navigate to="/login" />} />
                  )}

                  {!isAuthenticated && (
                    <Route path="/blacklist" element={<Navigate to="/login" />} />
                  )}

                  

                </>
              )}
            </>
          )}
          <Route path="/discord-verification" element={<DiscordVerification />} />
          <Route path="/blacklist" element={<Blacklist />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
  
  
}
