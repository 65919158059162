import { useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { initializeMainApp } from '../../assets/js/app.js';

function Blacklist() {

  const [userInfo, setUserInfo] = useState({
    username: 'User is Blacklisted',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });

  
  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (response.ok) {
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };
  
  const effectRan = useRef(false)

  useEffect(() => {
    if (effectRan.current === false) {

      function fetchBlacklist() {
        fetch(`${process.env.REACT_APP_API_URL}/api/blacklist`, {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
        })
          .then(response => response.json())
          .then(data => {
            // Process the fetched data here
            const blacklistInfo = data.blacklistinfo;
            const reason = blacklistInfo.reason;
            const reasontext = document.getElementById("reason");
            reasontext.innerHTML = reason;
          })
          .catch(error => {
            console.error('Error fetching blacklist data:', error);
          });
      }
    
      // Call the function to fetch and process the blacklist data
      fetchBlacklist();
      initializeMainApp();

    }

    return () => {
      effectRan.current = true;
    };
    
  }, []);
  
  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title" id="welcometext"></h4>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Virizon Blacklisted</a>
                    </li>
                    <li className="breadcrumb-item active">Blacklist Page</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>
            {/* end page-title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Your Account is Blacklisted</h4>
                    <br /> {/* Corrected line break */}
                    <p>
                      This action has been taken due to a violation of our terms and conditions.
                      If you believe this to be an error, please contact our support team for further assistance.
                    </p>
                    <div className="button-items">
                      <p><strong>Reason:</strong></p>
                      <div className="grid-container" id="reason"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container-fluid */}
        </div>
        {/* content */}
        <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
      </div>
      {/* ============================================================== */}
      {/* End Right content here */}
      {/* ============================================================== */}
    </div>
  );
}

export default Blacklist;
