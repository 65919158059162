import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function Purchase() {
  const [rbxusername, setRbxUsername] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePurchase = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Perform purchase request
    fetch(`${process.env.REACT_APP_API_URL}/api/purchase`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rbxusername,
        email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire('Success', 'Purchase successful. Please check your email.', 'success');
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .catch((error) => {
        console.error('Error during purchase:', error);
        Swal.fire('Error', error.message || 'Purchase Error Please Try Again', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        <div className="card card-pages shadow-none">
          <div className="card-body">
            <h5 className="font-18 text-center">Purchase Virizon Mgui.</h5>
            <form className="form-horizontal m-t-30" onSubmit={handlePurchase}>
              <div className="form-group">
                <div className="col-12">
                  <label>Roblox Username</label>
                  <input
                    className="form-control"
                    type="text"
                    required=""
                    placeholder="Roblox Username"
                    id="rbxusername"
                    value={rbxusername}
                    onChange={(e) => setRbxUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-12">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    required=""
                    placeholder="Email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group text-center m-t-10">
                <div className="col-12">
                  <button
                    className="btn btn-primary btn-block btn-lg waves-effect waves-light"
                    type="submit"
                    name="purchase"
                    id="purchaseButton"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      'Purchase'
                    )}
                  </button>
                </div>
              </div>
              <div className="form-group text-center m-t-10">
                <div className="col-12">

                  <a
                    className="btn btn-success btn-block btn-lg waves-effect waves-light"
                    href={`https://www.roblox.com/catalog/${process.env.REACT_APP_SHIRT_ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Shirt
                  </a>

                </div>
              </div>
              <div className="form-group row m-t-20 m-b-0">
                <div className="col-sm-5 text-left">
                  <Link to="/register" className="text-muted">
                    <i className="far fa-address-card"></i> Register
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Purchase;
