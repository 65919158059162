import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { initializeMainApp } from '../../assets/js/app.js';

function Admin() {

  const [isLoadingGenToken, setIsLoadingGenToken] = useState(false);
  const [isLoadingGenTokenPurchase, setIsLoadingGenTokenPurchase] = useState(false);
  const [isLoadingGenResetUrl, setIsLoadingGenResetUrl] = useState(false);
  const [isLoadingChangeMembership, setIsLoadingChangeMembership] = useState(false);

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });


  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (response.ok) {
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };
  
  const effectRan = useRef(false);
  
  useEffect(() => {
    if (effectRan.current === false) {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });
          if (response.ok) {
            const data = await response.json();
            const userData = data.data;
            const userInfoData = {
              username: userData.username,
              profile_pic: userData.profile_pic,
              discord_id: userData.discord_id,
              membership: userData.membership,
            };
            setUserInfo(userInfoData);
            updateUserInfo(userInfoData);
          } else {
            // Handle the case where the fetch request fails
            console.error('Error fetching user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetchUserInfo:', error);
        }
      };
  
      const updateUserInfo = (info) => {
        const profile_pic = document.getElementById('profile_pic');
        if (profile_pic) {
          profile_pic.src = info.profile_pic;
        }
      };
  
      fetchUserInfo();
      initializeMainApp();
    }
  
    return () => {
      effectRan.current = true;
    };
  }, []);
  
  const handleGenerateToken = async (event) => {
    event.preventDefault();
    setIsLoadingGenToken(true); // Start loading
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/gentoken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
        credentials: 'include', // Include cookies in the request
      });
  
      if (response.ok) {
        const data = await response.json();
        const successtoken = document.getElementById('token');
        successtoken.innerHTML = `
          <div class="alert alert-success" role="alert">
            <strong>Well done!</strong> You successfully generated token ${data.token}
          </div>
        `;
  
        Swal.fire('Success', 'Token Generated: ' + data.token, 'success');
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error during token generation:', error);
      Swal.fire('Error', error.message || 'Token Generation Error', 'error');
    } finally {
      setIsLoadingGenToken(false); // Stop loading
    }
  };
  
  const handleGenerateTokenPurchase = async (event) => {
    event.preventDefault();
    setIsLoadingGenTokenPurchase(true); // Start loading
  
    const email = document.getElementById('email').value;
    const purchaseMethod = document.getElementById('purchaseMethod').value;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/gentokenpurchase`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, purchaseMethod }),
        credentials: 'include', // Include cookies in the request
      });
  
      if (response.ok) {
        const data = await response.json();
        const successtoken = document.getElementById('token1');
        successtoken.innerHTML = `
          <div class="alert alert-success" role="alert">
            <strong>Well done!</strong> You successfully generated token ${data.token}
          </div>
        `;
  
        Swal.fire('Success', 'Token Generated: ' + data.token, 'success');
      } else {
        const data = await response.json();
      }
    } catch (error) {
      console.error('Error during token generation:', error);
      Swal.fire('Error', error.message || 'Token Generation Error', 'error');
    } finally {
      setIsLoadingGenTokenPurchase(false); // Stop loading
    }
  };
  
  const handleMembershipOptionChange = () => {
    const selectedOption = document.getElementById('membership').value;
    const blacklistReasonContainer = document.getElementById('blacklistReasonContainer');
  
    if (selectedOption === 'Blacklist') {
      blacklistReasonContainer.style.display = 'block';
    } else {
      blacklistReasonContainer.style.display = 'none';
    }
  };

  const handleGeneratePasswordUrl = async (event) => {
    event.preventDefault();
    setIsLoadingGenResetUrl(true); // Start loading
  
    const username = document.getElementById('username').value;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/genresetpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
        credentials: 'include', // Include cookies in the request
      });
  
      if (response.ok) {
        const data = await response.json();
        const successtoken = document.getElementById('reseturl');
        successtoken.innerHTML = `
          <div class="alert alert-success" role="alert">
            <strong>Well done!</strong> ${data.resetURL}</a>
          </div>
        `;
  
        Swal.fire('Success', 'Reset Url Generated Successfully', 'success');
      } else {
        const data = await response.json();
      }
    } catch (error) {
      console.error('Error during token generation:', error);
      Swal.fire('Error', error.message || 'Reset Url Generation Error', 'error');
    } finally {
      setIsLoadingGenResetUrl(false); // Stop loading
    }
  };
  
  const handleMembershipChanger = async (event) => {
    event.preventDefault();
    const membershipType = document.getElementById('membership').value;
    const username = document.getElementById('siteusername').value;
  
    let blacklistReason = 'None';
    if (membershipType === 'Blacklist') {
      blacklistReason = document.getElementById('blacklistReason').value;
    }
  
    setIsLoadingChangeMembership(true); // Start loading
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/changemembership`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, membershipType, blacklistReason }),
        credentials: 'include', // Include cookies in the request
      });
  
      if (response.ok) {
        Swal.fire('Success', 'Membership Changed to: ' + membershipType, 'success');
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error changing membership:', error);
      Swal.fire('Error', error.message || 'Membership Changer have a problem', 'error');
    } finally {
      setIsLoadingChangeMembership(false); // Stop loading
    }
  };
  

  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Admin</a>
                    </li>
                    <li className="breadcrumb-item active">Admin Access Page</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>
            {/* end page-title */}
            <div class="row">
              <div class="col-lg-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">Generate Token</h4>
                    <br />
                    <div id="token"></div>
                    <form onSubmit={handleGenerateToken}>
                      <div class="form-group">
                        <div>
                        <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            disabled={isLoadingGenToken} // Disable button when loading
                          >
                            {isLoadingGenToken ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Generate Token'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">Generate Token Purchase</h4>
                    <br />
                    <div id="token1"></div>
                    <form onSubmit={handleGenerateTokenPurchase}>
                      <div class="form-group">
                        <input type="email" class="form-control" id="email" placeholder="Enter email" required />
                      </div>
                      <div class="form-group">
                        <select class="form-control" id="purchaseMethod" required>
                          <option value="" selected disabled>
                            Select purchase method
                          </option>
                          <option value="Robux">Robux</option>
                          <option value="Gcash">Gcash</option>
                          <option value="Load">Load</option>
                          <option value="Paypal">Paypal</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <div>
                        <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            disabled={isLoadingGenTokenPurchase} // Disable button when loading
                          >
                            {isLoadingGenTokenPurchase ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Generate Token'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">Generate Reset Password Url</h4>
                    <br />
                    <div id="reseturl"></div>
                    <form onSubmit={handleGeneratePasswordUrl}>
                      <div class="form-group">
                        <input type="text" class="form-control" id="username" placeholder="Enter Username" required />
                      </div>
                      <div class="form-group">
                        <div>
                        <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            disabled={isLoadingGenResetUrl} // Disable button when loading
                          >
                            {isLoadingGenResetUrl ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Generate Reset Password URL'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">Membership Changer</h4>
                    <br />
                    <div class="form-group">
                      <input type="text" class="form-control" id="siteusername" placeholder="Username" required />
                    </div>
                    <form onSubmit={handleMembershipChanger}>
                      <div class="form-group">
                        <select class="form-control" id="membership" required onChange={handleMembershipOptionChange}>
                          <option value="" selected disabled>
                            Select a membership to change
                          </option>
                          <option value="Admin">Admin</option>
                          <option value="Moderator">Moderator</option>
                          <option value="Blacklist">Blacklist</option>
                          <option value="UnBlacklist">UnBlacklist</option>
                        </select>
                      </div>
                      <div class="form-group" id="blacklistReasonContainer" style={{ display: 'none' }}>
                        <input type="text" class="form-control" id="blacklistReason" placeholder="Enter blacklist reason" />
                      </div>
                      <div class="form-group">
                        <div>
                        <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            disabled={isLoadingChangeMembership} // Disable button when loading
                          >
                            {isLoadingChangeMembership ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Change Membership'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* container-fluid */}
          </div>
          {/* content */}
          <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
        </div>
        {/* ============================================================== */}
        {/* End Right content here */}
        {/* ============================================================== */}
      </div>
    </div>
  );
}

export default Admin;
