import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function ResetPassword() {
  const queryParameters = new URLSearchParams(window.location.search);
  const key = queryParameters.get("key");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [showForm, setShowForm] = useState(false); // Initialize to false
  const [isLoading, setIsLoading] = useState(false);
  const [invalidKey, setInvalidKey] = useState(false); // Track invalid key

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (newPassword, newConfirmPassword) => {
    if (newPassword !== newConfirmPassword) {
      setPasswordMatchError('Passwords do not match');
    } else {
      setPasswordMatchError('');
    }
  };

  const effectRan = useRef(false)
  useEffect(() => {
    // Fetch to check the reset key

    if (effectRan.current === false) {

      fetch(`${process.env.REACT_APP_API_URL}/api/check-reset-key?key=${key}`)
        .then(response => {
          if (response.ok) {
            setShowForm(true); // Show the form
            setInvalidKey(false); // Reset invalid key state
          } else {
            setShowForm(false); // Hide the form
            setInvalidKey(true); // Set invalid key state
          }
        })
        .catch(error => {
          console.error('Error checking reset key:', error);
          setShowForm(false); // Hide the form
          setInvalidKey(true); // Set invalid key state
        });

    }

    return () => {
      effectRan.current = true
    };

  }, [key]);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      // Check if passwords match
      if (password !== confirmPassword) {
        setPasswordMatchError('Passwords do not match');
        return;
      }

      setIsLoading(true);

      // Perform password reset request
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key,
          password,
        })
      });

      if (response.ok) {

        const data = await response.json();
        const setCookie = data.cookie;
        if (setCookie) {
          document.cookie = setCookie;
        }
        
        Swal.fire('Success', data.message, 'success');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const data = await response.json();
        Swal.fire("Error", data.message, "error");
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      Swal.fire("Error", error.message || "Password Reset Error. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabled = password !== confirmPassword || isLoading || password === '' || confirmPassword === '';

  return (
    <>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        <div className="card card-pages shadow-none">
          <div className="card-body">
            {invalidKey ? (
              <div className="alert alert-danger">Invalid reset key</div>
            ) : null}
            {showForm ? (
              <>
                <h5 className="font-18 text-center">Reset Password</h5>
                <form className="form-horizontal m-t-30" onSubmit={handleResetPassword}>
                  <div className="form-group">
                    <div className="col-12">
                      <label>New Password</label>
                      <input
                        className="form-control"
                        type="password"
                        required=""
                        placeholder="New Password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-12">
                      <label>Confirm Password</label>
                      <input
                        className="form-control"
                        type="password"
                        required=""
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      {passwordMatchError && <small className="form-text text-danger">{passwordMatchError}</small>}
                    </div>
                  </div>
                  <div className="form-group text-center m-t-20">
                    <div className="col-12">
                      <button
                        className="btn btn-primary btn-block btn-lg waves-effect waves-light"
                        type="submit"
                        name="reset"
                        id="resetButton"
                        disabled={isDisabled}
                      >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                          'Reset Password'
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="form-group row m-t-30 m-b-0">
                    <div className="col-sm-5 text-left">
                      <Link to="/login" className="text-muted">
                        <i className="far fa-address-card"></i> Login Page
                      </Link>
                    </div>
                  </div>
                </form>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
