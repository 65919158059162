import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { initializeMainApp } from '../../assets/js/app.js';

function Publish() {

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCookie, setIsLoadingCookie] = useState(false);
  const [isLoadingCreating, setIsLoadingCreating] = useState(false);
  const [isLoadingPublishing, setIsLoadingPublishing] = useState([]);
  const [isLogin, setIsLogin] = useState(true);
  const [authData, setAuthData] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState("https://i.ibb.co/z2Y0C3n/Def-Thumbnail-1.webp");
  const [iconPreview, setIconPreview] = useState("https://prod.docsiteassets.roblox.com/assets/publishing/experience-metadata/Icon-Default.jpg");
  const [ownerGroups, setOwnerGroups] = useState([]);
  const [visibleGames, setVisibleGames] = useState(5);
  const [ownGames, setOwnGames] = useState([]);

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });


  const [formData, setFormData] = useState({
    gamename: '',
    gamedescription: '',
    gameprivacy: 'Public',
    gameowner: "OnlyMe",
  });

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };


  function handleCookieLogin(e) {
    e.preventDefault();

    setIsLoadingCookie(true);

    const cookie = document.getElementById('cookie').value;

    try {
      fetch(`${process.env.REACT_APP_API_URL}/api/publish/cookie-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cookie,
        }),
        credentials: 'include',
      })
        .then((response) => {
          if (response.ok) {
            Swal.fire('Success', 'Login Successfully', 'success');
            checkAuth();
            setIsLogin(true)
          } else {
            return response.json().then((data) => {
              Swal.fire('Error', data.message || 'Failed to Login. Please Try Again', 'error');
            });
          }
        })
        .catch((error) => {
          console.error('Error during login:', error);
          Swal.fire('Error', error.message || 'Failed to login. Please Try Again', 'error');
        })
        .finally(() => {
          // Revert the button state
          setIsLoadingCookie(false);
        });
    } catch (error) {
      console.error('Error during login:', error);
      Swal.fire('Error', error.message || 'Failed to login. Please Try Again', 'error');
    }
  }

  function handleLogoutRbx() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to log out. Do you want to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, logout',
      cancelButtonText: 'No, cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/publish/logout`, {
          method: 'GET',
          credentials: 'include',
        })
          .then((response) => {
            if (response.ok) {
              setIsLoading(false);
              setIsLogin(false);
            } else {
              setIsLoading(false);

              return response.text().then((errorMessage) => {
                throw new Error(errorMessage);
              });
            }
          })
          .catch((error) => {
            console.error('Logout failed:', error);
            Swal.fire('Error', 'Logout failed', 'error');
          });
      }
    });
  }

  const handleThumbnailUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setThumbnailPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIconUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setIconPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateGame = async (e) => {
    e.preventDefault();
    setIsLoadingCreating(true);

    const thumbnail = document.getElementById('thumbnail').files[0];
    const icon = document.getElementById('icon').files[0];

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('gamename', formData.gamename);
      formDataToSend.append('gamedescription', formData.gamedescription);
      formDataToSend.append('gameprivacy', formData.gameprivacy);
      formDataToSend.append('gameowner', formData.gameowner);

      if (thumbnailPreview) {
        formDataToSend.append('thumbnail', thumbnail);
      }
      if (iconPreview) {
        formDataToSend.append('icon', icon);
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/publish/create-game`, {
        method: 'POST',
        body: formDataToSend,
        credentials: 'include',
      });

      if (response.ok) {
        console.log('Game created successfully');
        const data = await response.json();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Game created successfully',
        });
        setFormData({
          gamename: '',
          gamedescription: '',
          gameprivacy: 'Public',
          gameowner: 'OnlyMe',
        });
        setThumbnailPreview("https://i.ibb.co/z2Y0C3n/Def-Thumbnail-1.webp");
        setIconPreview("https://prod.docsiteassets.roblox.com/assets/publishing/experience-metadata/Icon-Default.jpg");

        function openNewTab(url) {
          setTimeout(function () {
            window.open(url, "_blank");
          }, 3000);
        }

        // Example usage:
        openNewTab(`https://www.roblox.com/games/${data.gameid}/`);

      } else {
        console.error('Error creating game');

        let errorMessage = 'Game Creation Error. Please Try Again';
        try {
          const errorData = await response.json();
          if (errorData && errorData.message) {
            errorMessage = errorData.message;
          }
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorMessage,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      let errorMessage = 'An error occurred. Please try again later.';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (error instanceof Response) {
        try {
          const errorData = await error.json();
          if (errorData && errorData.message) {
            errorMessage = errorData.message;
          }
        } catch (parseError) {
          console.error('Error parsing JSON response:', parseError);
        }
      }

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      setIsLoadingCreating(false);
    }
  };

  const handlePublish = async (gameId, index) => {
    setIsLoadingPublishing((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = true;
      return updatedStates;
    });

    const data = {
      gameid: gameId,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/publish/publish-game`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include',
      });

      if (response.ok) {

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Publish successfully',
        });
      } else {
        console.error('Error creating game');
        let errorMessage = 'Game Configuration Error. Please Try Again';
        try {
          const errorData = await response.json();
          if (errorData && errorData.message) {
            errorMessage = errorData.message;
          }
        } catch (error) {
          console.error('Error parsing JSON response:', error);
        }

        // Show error notification
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorMessage,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      let errorMessage = 'An error occurred. Please try again later.';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (error instanceof Response) {
        try {
          const errorData = await error.json();
          if (errorData && errorData.message) {
            errorMessage = errorData.message;
          }
        } catch (parseError) {
          console.error('Error parsing JSON response:', parseError);
        }
      }

      // Show error notification for network or other errors
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorMessage,
      });
    } finally {
      // Reset the loading state
      setIsLoadingPublishing((prevStates) => {
        const updatedStates = [...prevStates];
        updatedStates[index] = false;
        return updatedStates;
      });
    }
  };

  const handleLoadMore = () => {
    setVisibleGames(visibleGames + 5);
  };


  const checkAuth = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/publish/check-auth`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        setIsLogin(true)
        setIsLoading(false)
        const data = await response.json();
        const fetchedAuthData = data.data;
        setAuthData(fetchedAuthData);
        setOwnerGroups(fetchedAuthData.owngroups);
        setOwnGames(fetchedAuthData.owngames);

      } else {
        setIsLoading(false)
        setIsLogin(false)
        console.error('Error fetching Auth data:', response.statusText);
      }
    } catch (error) {
      console.error('Error during fetchUserInfo:', error);
    }
  };

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });
          if (response.ok) {
            const data = await response.json();
            const userData = data.data;
            const userInfoData = {
              username: userData.username,
              profile_pic: userData.profile_pic,
              discord_id: userData.discord_id,
              membership: userData.membership,
            };
            setUserInfo(userInfoData);
            updateUserInfo(userInfoData);
          } else {
            // Handle the case where the fetch request fails
            console.error('Error fetching user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetchUserInfo:', error);
        }
      };

      const updateUserInfo = (info) => {
        const profile_pic = document.getElementById('profile_pic');
        if (profile_pic) {
          profile_pic.src = info.profile_pic;
        }
      };

      fetchUserInfo();
      initializeMainApp();
      checkAuth();
    }

    return () => {
      effectRan.current = true;
    };
  }, []);


  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Publish A Game</a>
                    </li>
                    <li className="breadcrumb-item active">Site Publish Page</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>
            {isLoading ? (
              <div className="col-lg-12 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              isLogin ? (
                <div className="row">
                  <div className="col-md-4 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="mt-0 header-title">Account Info</h4>
                        <p className="text-success">Sign is Account is {authData?.UserName}</p>
                        <div className="form-group text-center">
                          <img
                            className="rounded-circle"
                            src={authData?.ThumbnailUrl}
                            alt="Avatar"
                            style={{ width: '256px', height: '256px' }}
                          />
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            name="logout"
                            className="btn btn-danger btn-block"
                            onClick={handleLogoutRbx}
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-8">
                    <div class="card m-b-30">
                      <div class="card-body">
                        <h4 class="mt-0 header-title">Create Game</h4>
                        <br />
                        <form onSubmit={handleCreateGame} encType="multipart/form-data">
                          <ul className="nav nav-pills nav-justified" role="tablist">
                            <li className="nav-item waves-effect waves-light">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#gamesettings-1"
                                role="tab"
                                aria-selected="true"
                              >
                                <span className="d-none d-md-block">Game Settings</span>
                                <span className="d-block d-md-none">
                                  <i className="mdi mdi-settings h5"></i>
                                </span>
                              </a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#thumbnail-1"
                                role="tab"
                                aria-selected="false"
                              >
                                <span className="d-none d-md-block">Thumbnail</span>
                                <span className="d-block d-md-none">
                                  <i className="mdi mdi-image h5"></i>
                                </span>
                              </a>
                            </li>
                            <li className="nav-item waves-effect waves-light">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#icon-1"
                                role="tab"
                              >
                                <span className="d-none d-md-block">Icon</span>
                                <span className="d-block d-md-none">
                                  <i className="mdi mdi-file-image h5"></i>
                                </span>
                              </a>
                            </li>
                          </ul>

                          <div className="tab-content">

                            <div className="tab-pane p-3 active" id="gamesettings-1" role="tabpanel">
                              <div className="form-group">
                                <label htmlFor="gamename">Game Name</label>
                                <input
                                  onChange={handleFormChange}
                                  value={formData.gamename}
                                  type="text"
                                  className="form-control"
                                  id="gamename"
                                  name="gamename"
                                  placeholder="Game Name"
                                  required=""
                                  fdprocessedid="v665s"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="gamedescription">Description</label>
                                <textarea
                                  onChange={handleFormChange}
                                  value={formData.gamedescription}
                                  type="text"
                                  className="form-control"
                                  id="gamedescription"
                                  name="gamedescription"
                                  placeholder="Description"
                                  rows="5"
                                  cols="50"
                                ></textarea>
                              </div>
                              <div className="form-group">
                                <label htmlFor="gameprivacy">Privacy</label>
                                <select
                                  name="gameprivacy"
                                  id="gameprivacy"
                                  className="form-control"
                                  fdprocessedid="xewr3i"
                                  onChange={handleFormChange}
                                >
                                  <option value="Public">Public</option>
                                  <option value="Private">Private</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="gameowner">Owner Creator</label>
                                <select
                                  name="gameowner"
                                  id="gameowner"
                                  className="form-control"
                                  fdprocessedid="x8i597"
                                  onChange={handleFormChange}
                                >
                                  <option value="OnlyMe">Only Me</option>
                                  {ownerGroups.map((group) => (
                                    <option key={group.value} value={group.value}>
                                      {group.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="tab-pane p-3" id="thumbnail-1" role="tabpanel">
                              <div className="mt-3 d-flex justify-content-center">
                                {thumbnailPreview && (
                                  <img
                                    src={thumbnailPreview}
                                    alt="Thumbnail Preview"
                                    className="img-thumbnail"
                                    style={{
                                      width: '280px', // Set the width to 640 pixels
                                      height: '180px', // Set the height to 360 pixels
                                      border: 'none', // Remove the border
                                    }}
                                  />
                                )}
                              </div>

                              <div className="text-left mt-3">
                                <label htmlFor="thumbnail">Thumbnail</label>
                                <div className="custom-file custom-file-primary">
                                  <input type="file" className="custom-file-input" id="thumbnail" name="thumbnail" accept="image/*" onChange={handleThumbnailUpload} />
                                  <label className="custom-file-label" htmlFor="profilepicture">Choose file</label>
                                </div>
                              </div>
                            </div>

                            <div className="tab-pane p-3" id="icon-1" role="tabpanel">
                              <div className="mt-3 d-flex justify-content-center">
                                {iconPreview && (
                                  <img
                                    src={iconPreview}
                                    alt="Icon Preview"
                                    className="img-thumbnail"
                                    style={{
                                      width: '256px', // Set the width to 256 pixels
                                      height: '256px', // Set the height to 256 pixels
                                      border: 'none', // Remove the border
                                    }}
                                  />
                                )}
                              </div>

                              <div className="text-left mt-3">
                                <label htmlFor="icon">Game Icon</label>
                                <div className="custom-file custom-file-primary">
                                  <input type="file" className="custom-file-input" id="icon" name="icon" accept="image/*" onChange={handleIconUpload} />
                                  <label className="custom-file-label" htmlFor="profilepicture">Choose file</label>
                                </div>
                              </div>
                            </div>

                          </div>

                          <div className="form-group">
                            <button
                              className="btn btn-primary btn-block waves-effect waves-light"
                              type="submit"
                              name="addgame"
                              id="submitButton"
                              disabled={isLoadingCreating}
                            >
                              {isLoadingCreating ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Create Game"
                              )}
                            </button>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <h4 className="mt-0 header-title mb-4">Your Games</h4>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Game ID</th>
                              <th>Game Name</th>
                              <th>Game Link</th>
                              <th>Publish</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ownGames.slice(0, visibleGames).map((games, index) => (
                              <tr key={games.gameid}>
                                <td>{games.gameid}</td>
                                <td>{games.gamename}</td>
                                <td>
                                  <a
                                    href={`https://www.roblox.com/games/${games.gameid}`}
                                    target="_blank"
                                    style={{ color: '#0077ff', textDecoration: 'underline' }}
                                  >
                                    Click Here
                                  </a>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    name="publish"
                                    className="btn btn-primary waves-effect waves-light"
                                    id={`publishbutton-${games.gameid}`}
                                    onClick={() => handlePublish(games.gameid, index)}
                                    disabled={isLoadingPublishing[index]}
                                    fdprocessedid="qity5"
                                  >

                                    {isLoadingPublishing[index] ? (
                                      <span
                                        className="spinner-border spinner-border-sm mr-1"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : null}
                                    {isLoadingPublishing[index] ? 'Publishing' : 'Publish Game'}

                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {visibleGames < ownGames.length && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                </div>
              ) : (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card m-b-30">
                      <div className="card-body">
                        <h4 className="mt-0 header-title">Cookie Login</h4>
                        <form onSubmit={handleCookieLogin}>
                          <div className="form-group mt-3">
                            <label>Roblox Cookie</label>
                            <p className="text-success">Please bypass your cookie before logging in. Else you will get invalid Cookie <a href="https://rblxfresh.net/">Click here to Bypass</a></p>
                            <input type="text" className="form-control" required placeholder="Roblox Cookie here" id="cookie" />
                          </div>
                          <div className="form-group">
                            <div>
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                                name="addgame"
                                id="submitButton"
                                disabled={isLoadingCookie} // Disable button when loading
                              >
                                {isLoadingCookie ? (
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                  'Login'
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}

            {/* end page-title */}

            {/* container-fluid */}
          </div>
          {/* content */}
          <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
        </div>
        {/* ============================================================== */}
        {/* End Right content here */}
        {/* ============================================================== */}
      </div>
    </div>
  );
}

export default Publish;
