import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <>
      <div className="error-bg"></div>
      <div className="account-pages">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="card shadow-lg">
                <div className="card-block">
                  <div className="text-center p-3">
                    <h1 className="error-page mt-4"><span>404!</span></h1>
                    <h4 className="mb-4 mt-5">Sorry, page not found</h4>
                    <p className="mb-4">It will be as simple as Occidental in fact, it will Occidental to an English person</p>
                    <Link to="/" className="btn btn-primary mb-4 waves-effect waves-light"><i className="mdi mdi-home"></i> Back to Dashboard</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
