import { useEffect, useState, useRef} from 'react';

async function BlacklistMiddleware() {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blacklist`, {
      method: 'GET', // Adjust the HTTP method as needed
      credentials: 'include', // Include cookies in the request
    });

    if (response.ok) {
      const data = await response.json();
      return data.blacklistinfo.status; // Get the blacklist status from the response
    } else {
      const data = await response.json();
      throw new Error(data.message);
    }
  } catch (error) {
    console.error('Error checking blacklist status:', error);
    return false; // Assume user is not blacklisted in case of an error
  }
}


export default function useBlacklistInfo() {
  const [isBlacklisted, setIsBlacklisted] = useState(null); // Initialize as null for loading state
  const effectRan = useRef(false)

  useEffect(() => {
    if (effectRan.current === false){
      async function checkBlacklist() {
        const isBlacklisted = await BlacklistMiddleware();
        setIsBlacklisted(isBlacklisted);
      }
      checkBlacklist();
    }

    return () => {
      effectRan.current = true
    };
    
  }, []);

  return { isBlacklisted };
}
