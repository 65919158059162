import React, { useState, useRef } from 'react';

const Cookie = () => {
  const params = new URLSearchParams(window.location.search);
  const cookieValue = params.get("cookie");

  const [copied, setCopied] = useState(false);
  const textareaRef = useRef(null);

  const copyToClipboard = () => {
    const textArea = textareaRef.current;
    textArea.select();
    document.execCommand("copy");
    setCopied(true);
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="col-12 col-md-8 col-lg-8">
        <div className="card text-center p-4">
          {cookieValue && (
            <div>
              <textarea
                className="form-control mb-3"
                rows="15"
                cols="50"
                value={cookieValue}
                readOnly
                ref={textareaRef}
              />
              {!copied ? (
                <button
                  className="btn btn-success"
                  onClick={copyToClipboard}
                >
                  Copy
                </button>
              ) : (
                <p>Text Copied to Clipboard!</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cookie;
