import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { initializeMainApp } from '../../assets/js/app.js';

function Settings() {

  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [isLoadingUsername, setIsLoadingUsername] = useState(false);
  const [isLoadingResetDiscord, setIsLoadingResetDiscord] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);
  const [isLoadingLogoutSession, setIsLoadingLogoutSession] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Track button disabled state

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });


  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };

  const checkPasswordMatch = (newPassword, newConfirmPassword) => {
    if (newPassword !== newConfirmPassword) {
      setPasswordMatchError('Passwords do not match');
      setIsButtonDisabled(true);
    } else {
      setPasswordMatchError('');
      setIsButtonDisabled(false);
    }
  };

  const isDisabled = password !== confirmPassword || password === '' || confirmPassword === '';

  async function handleChangePassword(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      Swal.fire('Error', 'New password and confirm password do not match', 'error');
      return;
    }

    setIsLoadingPassword(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/update-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          oldPassword: document.getElementById('oldpassword').value,
          newPassword: password,
        }),
      });

      if (response.ok) {
        Swal.fire('Success', 'Password Change successfully', 'success');
        const data = await response.json();
        const setCookie = data.cookie;
        if (setCookie) {
          document.cookie = setCookie;
        }
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error during password change:', error);
      Swal.fire('Error', error.message || 'Password Change Error. Please try again.', 'error');
    } finally {
      setIsLoadingPassword(false);
    }
  }

  function handleChangeUsername(e) {
    e.preventDefault();
    const password = document.getElementById('usernamepassword').value;
    const newUsername = document.getElementById('newusername').value;

    setIsLoadingUsername(true);
    // Perform username change request
    fetch(`${process.env.REACT_APP_API_URL}/api/update-username`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        password,
        newUsername,
      }),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire('Success', 'Username changed successfully', 'success');
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .catch((error) => {
        console.error('Error during username change:', error);
        Swal.fire('Error', error.message || 'Username change error. Please try again.', 'error');
      })
      .finally(() => {
        setIsLoadingUsername(false);
      });
  }

  function handleChangeEmail(e) {
    e.preventDefault();
    const password = document.getElementById('emailpassword').value;
    const newEmail = document.getElementById('newemail').value;

    setIsLoadingEmail(true);

    // Perform email update request
    fetch(`${process.env.REACT_APP_API_URL}/api/update-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies in the request
      body: JSON.stringify({
        password,
        newEmail,
      }),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire('Success', 'Email updated successfully', 'success');
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .catch((error) => {
        console.error('Error during email update:', error);
        Swal.fire('Error', error.message || 'Email Update Error. Please try again.', 'error');
      })
      .finally(() => {
        setIsLoadingEmail(false);
      });
  }

  function previewProfilePicture(e) {
    const fileInput = e.target;
    const previewImage = document.getElementById('profilepicturepreview');

    if (fileInput.files && fileInput.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        previewImage.src = e.target.result;
        previewImage.style.display = 'block';
      };

      reader.readAsDataURL(fileInput.files[0]);
    } else {
      previewImage.src = '#';
      previewImage.style.display = 'none';
    }
  }

  function handleUpdateProfilePicture(e) {
    e.preventDefault();
    const profilePicture = document.getElementById('profilepicture').files[0];

    setIsLoadingProfilePicture(true);
    // Prepare form data
    const formData = new FormData();
    formData.append('profilePicture', profilePicture);

    fetch(`${process.env.REACT_APP_API_URL}/api/update-profile-picture`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        // Display a success message
        console.log(data)
        const userInfoData = {
          username: userInfo.username,
          profile_pic: data.imageUrl,
          discord_id: userInfo.discord_id,
          membership: userInfo.membership,
        };
        setUserInfo(userInfoData);
        Swal.fire('Success', 'Profile picture updated successfully', 'success');
      })
      .catch((error) => {
        console.error('Error during profile picture update:', error);
        Swal.fire(
          'Error',
          error.message || 'Profile Picture Update Error. Please try again.',
          'error'
        );
      })
      .finally(() => {
        setIsLoadingProfilePicture(false);
      });
  }

  const handleResetDiscordId = async (e) => {
    e.preventDefault();

    const password = document.getElementById('reset-discord-password').value;

    setIsLoadingResetDiscord(true);

    // Perform email update request
    fetch(`${process.env.REACT_APP_API_URL}/api/reset-discord`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
      }),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire('Success', 'Reset successfully', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .catch((error) => {
        console.error('Error during discord reset:', error);
        Swal.fire('Error', error.message || 'Reset Error. Please try again.', 'error');
      })
      .finally(() => {
        setIsLoadingResetDiscord(false);
      });

  };


  function handleLogoutSession(event) {
    event.preventDefault();

    setIsLoadingLogoutSession(true);

    async function logoutSession() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout-all-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({}),
        });

        if (response.ok) {
          Swal.fire('Success', 'Logout Session successfully', 'success');

          const data = await response.json();
          const setCookie = data.cookie;
          if (setCookie) {
            document.cookie = setCookie;
          }
        } else {
          const data = await response.json();
          throw new Error(data.message);
        }
      } catch (error) {
        console.error('Error during Logging out your session:', error);
        Swal.fire('Error', error.message || 'Logout Error. Please try again.', 'error');
      } finally {
        setIsLoadingLogoutSession(false);
      }
    }

    logoutSession();
  }


  const effectRan = useRef(false)

  useEffect(() => {

    if (effectRan.current === false) {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });
          if (response.ok) {
            const data = await response.json();
            const userData = data.data;
            const userInfoData = {
              username: userData.username,
              profile_pic: userData.profile_pic,
              discord_id: userData.discord_id,
              membership: userData.membership,
            };
            setUserInfo(userInfoData);
            updateUserInfo(userInfoData);
          } else {
            // Handle the case where the fetch request fails
            console.error('Error fetching user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetchUserInfo:', error);
        }
      };

      const updateUserInfo = (info) => {
        const profile_pic = document.getElementById('profile_pic');
        const previewImage = document.getElementById('profilepicturepreview');
        if (profile_pic) {
          previewImage.src = info.profile_pic;
          previewImage.style.display = 'block';
          profile_pic.src = info.profile_pic;
        }
      };


      fetchUserInfo();
      initializeMainApp();
    }

    return () => {
      effectRan.current = true
    };
  }, []);


  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {/* Top Bar End */}
      {/* ========== Left Sidebar Start ========== */}
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      {/* Left Sidebar End */}
      {/* ============================================================== */}
      {/* Start right Content here */}
      {/* ============================================================== */}
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Settings</a>
                    </li>
                    <li className="breadcrumb-item active">Settings Page</li>
                  </ol>
                </div>
              </div>
              {/* end row */}
            </div>
            {/* end page-title */}

            <div className="row">

              <div className="col-lg-4">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Update Profile Picture</h4>
                    <p className="text-success">Please select a new profile picture.</p>
                    <form onSubmit={handleUpdateProfilePicture}>
                      <div className="mt-3 d-flex justify-content-center">
                        <img id="profilepicturepreview" src="#" alt="Preview" style={{ width: '250px', height: '250px', display: 'none', borderRadius: '50%' }} />
                      </div>
                      <div className="text-center mt-3">
                        <label htmlFor="profilepicture">Profile Picture</label>
                        <div className="custom-file custom-file-primary">
                          <input type="file" className="custom-file-input" id="profilepicture" onChange={previewProfilePicture} required />
                          <label className="custom-file-label" htmlFor="profilepicture">Choose file</label>
                        </div>
                      </div>
                      <div className="form-group text-center mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          id="updateprofilepicturebutton"
                          disabled={isLoadingProfilePicture} // Disable button when loading
                        >
                          {isLoadingProfilePicture ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                            'Update Profile Picture'
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>



              <div className="col-lg-8">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Change Username</h4>
                    <p className="sub-title">Please enter your password and new username.</p>
                    <form onSubmit={handleChangeUsername}>
                      <div className="form-group">
                        <label>New Username</label>
                        <input type="text" className="form-control" required placeholder="New Username" id="newusername" />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" required placeholder="Password" id="usernamepassword" />
                      </div>
                      <div className="form-group">
                        <div>
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            id="changeusername"
                            disabled={isLoadingUsername} // Disable button when loading
                          >
                            {isLoadingUsername ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Change Username'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>


              <div className="col-lg-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Change Password</h4>
                    <p className="sub-title">Please enter your old and new password.</p>

                    <form onSubmit={handleChangePassword}>
                      <div className="form-group">
                        <label>Old Password</label>
                        <input type="password" className="form-control" required placeholder="Old Password" id="oldpassword" />
                      </div>
                      <div className="form-group">
                        <label>New Password</label>
                        <input type="password" className="form-control" required placeholder="New Password" id="newpassword" onInput={handlePasswordChange} />
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input type="password" className="form-control" required placeholder="Confirm Password" id="confirmpassword" onInput={handleConfirmPasswordChange} />
                        {passwordMatchError && <small className="form-text text-danger">{passwordMatchError}</small>}
                      </div>
                      <div className="form-group">
                        <div>
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            name="changepassword"
                            id="submitButton"
                            disabled={isLoadingPassword} // Disable button when loading
                          >
                            {isLoadingPassword ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Change Password'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Update Discord ID</h4>
                    <p className="sub-title">Please enter your password.</p>
                    <form onSubmit={handleResetDiscordId}>

                      <div className="form-group">
                        <label> Password</label>
                        <input type="password" className="form-control" required placeholder="Password" id="reset-discord-password" />
                      </div>
                      <div className="form-group">
                        <div>
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            name="resetdiscord"
                            id="submitButton"
                            disabled={isLoadingResetDiscord} // Disable button when loading
                          >
                            {isLoadingResetDiscord ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Reset Discord Linked Account'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Logout All Sessions</h4>
                    <p className="sub-title">By Clicking this off all your session will be signing out.</p>
                    <form onSubmit={handleLogoutSession}>
                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          id="logoutsessionbutton"
                          disabled={isLoadingLogoutSession} // Disable button when loading
                        >
                          {isLoadingLogoutSession ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                            'Logout All Session'
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>



              <div className="col-lg-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Change Email</h4>
                    <p className="sub-title">Please enter your password and new email.</p>
                    <form onSubmit={handleChangeEmail}>
                      <div className="form-group">
                        <label>New Email</label>
                        <input type="email" className="form-control" required placeholder="New Email" id="newemail" />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" required placeholder="Password" id="emailpassword" />
                      </div>
                      <div className="form-group">
                        <div>
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            id="changeemail"
                            disabled={isLoadingEmail} // Disable button when loading
                          >
                            {isLoadingEmail ? (
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                              'Change Email'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* END ROW */}
          </div>
          {/* container-fluid */}
        </div>
        {/* content */}
        <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
      </div>
      {/* ============================================================== */}
      {/* End Right content here */}
      {/* ============================================================== */}
    </div>
  );

}

export default Settings;
