import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

Modal.setAppElement('#root');

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const isDiscordLoginCallbackCalled = useRef(false);

  const handleLogin = async (username, password, captcha) => {
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
          captcha: captcha,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const setCookie = data.cookie;
        if (setCookie) {
          document.cookie = setCookie;
        }

        Swal.fire('Success', data.message, 'success');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const data = await response.json();
        Swal.fire("Error", data.message || "Login Error. Please Try Again", "error");
      }
    } catch (error) {
      console.error('Error during login:', error);
      Swal.fire("Error", error.message || "Login Error. Please Try Again", "error");
    } finally {
      setIsLoading(false);
      setCaptchaValue(null)
    }

  }

  const showLoginCaptcha = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!captchaValue) {
      setShowCaptchaModal(true);
      return;
    }
  };

  const handleDiscordLoginClick = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/discord-get-url`);

      if (response.ok) {
        const data = await response.json();
        const discordLoginUrl = data.Login;
        window.location.href = discordLoginUrl;
      } else {
        throw new Error('Failed to fetch Discord login URL');
      }
    } catch (error) {
      console.error('Error during Discord login:', error);
      Swal.fire('Error', error.message || 'Discord Login Error. Please Try Again', 'error');
    }
  };

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const discordLoginCallback = async () => {
        try {
          const urlParams = new URLSearchParams(window.location.search);
          const code = urlParams.get('code');

          if (code && !isDiscordLoginCallbackCalled.current) {
            isDiscordLoginCallbackCalled.current = true;

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/discord-auth-logincallback?code=${code}`);

            if (response.status === 200) {
              const data = await response.json();
              console.log(data)
              const setCookie = data.cookie;
              if (setCookie) {
                document.cookie = setCookie;
                console.log("Cookie Set")
              }

              Swal.fire('Success', data.message, 'success');

              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              const data = await response.json();
              throw new Error(data.message || 'An error occurred during login.');
            }
          }
        } catch (error) {
          console.error('Error during Discord login callback:', error);
          Swal.fire('Error', error.message || 'An error occurred during Discord login.', 'error');
        }
      };

      discordLoginCallback();
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  return (
    <>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        <div className="card card-pages shadow-none">
          <div className="card-body">
            <h5 className="font-18 text-center">Sign in to continue to Virizon Mgui.</h5>
            <form className="form-horizontal m-t-30" onSubmit={(e) => showLoginCaptcha(e)}>
              <div className="form-group text-center m-t-20">
                <div className="col-12">
                  <button
                    className="btn btn-primary btn-block btn-lg waves-effect waves-light"
                    type="button"
                    onClick={handleDiscordLoginClick}
                  >
                    <i className="fab fa-discord"></i> Login to Discord
                  </button>
                </div>
              </div>
              <div className="form-group">
                <div className="col-12">
                  <label>Username</label>
                  <input
                    className="form-control"
                    type="text"
                    required=""
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-12">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type="password"
                    required=""
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group text-center m-t-20">
                <div className="col-12">
                  <button
                    className="btn btn-primary btn-block btn-lg waves-effect waves-light"
                    type="submit"
                    name="login"
                    id="loginButton"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                      'Log In'
                    )}
                  </button>
                </div>
              </div>
              <div className="form-group row m-t-20 m-b-0">
                <div className="col-sm-7">
                  <Link to="/forgot" className="text-muted">
                    <i className="fa fa-lock m-r-5"></i> Forgot your password?
                  </Link>
                </div>
                <div className="col-sm-5 text-right">
                  <Link to="/register" className="text-muted">
                    <i className="far fa-address-card"></i> Register
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* CAPTCHA Modal */}
      <Modal
        isOpen={showCaptchaModal}
        onRequestClose={() => {
          setShowCaptchaModal(false);
          setIsLoading(false);
        }}
        contentLabel="CAPTCHA Modal"
        className="captcha-modal"
        overlayClassName="captcha-modal-overlay"
      >
        <button
          className="btn btn-danger close-button mb-5"
          onClick={() => {
            setShowCaptchaModal(false);
            setIsLoading(false);
          }}
        >
          X
        </button>

        <ReCAPTCHA
          sitekey="6LdTrMcoAAAAANBfdTku3iIQVoTmLZ8tSEFbUf-6"
          onChange={(value) => {
            setCaptchaValue(value);
            setShowCaptchaModal(false);
            handleLogin(username, password, value);
          }}
        />

      </Modal>

    </>
  );
}

export default Login;
